<template>

  <v-form
    ref="registerForm"
    v-model="valid">

    <v-dialog
      persistent
      v-model="dialogEntityNotRegistred"
      width="500"
    >
      <v-card>
        <v-card-text>
          <v-layout column>
            <v-flex
              class="text-xs-center"
              xs12>
              <v-icon
                color="red darken-1"
                size="94"
              >
                mdi-close-circle-outline
              </v-icon>
            </v-flex>
            <v-flex xs12>
              <h3
                class="text-xs-center"
              >
                Entidade não cadastrada
              </h3>
            </v-flex>
            <v-flex xs12>
              <p
                class="text-xs-center"
              >
                Caso você seja o responsável pela entidade, selecione
                cadastrar para concluir o cadastro.
              </p>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="red"
            flat
            @click="dialogEntityNotRegistred = false"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1 white--text"
            :to="'/cadastro/entidade'"
          >
            Cadastrar Entidade
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-text-field
      v-model="entitySearch"
      :mask="getMask(entitySearch)"
      :rules="getValidationRulesForCpfCnpj(entitySearch)"
      required
      small
      box
      label="CPF ou CNPJ da Entidade"
    >
      <template v-slot:append-outer>
        <v-btn
          color="green"
          fab
          small
          @click="searchPerson()"
        >
          <v-icon
            color="white"
          >
            mdi-magnify
          </v-icon>
        </v-btn>
      </template>

    </v-text-field>
    <span
      class="mb-4"
      v-if="entityFound">
      Entidade: {{entityFound.name}}
    </span>

    <v-text-field
      label="CPF"
      v-model.trim="formData.cpf"
      :rules="cpfRules"
      required
      mask="###.###.###-##"
      :readonly="pending"
    />

    <v-text-field
      label="Nome"
      v-model.trim="formData.name"
      :rules="nameRules"
      required
      :readonly="pending"
    />

    <v-text-field
      label="E-mail"
      v-model.trim="formData.email"
      type="email"
      :rules="emailRules"
      :readonly="pending"
      required
    />

    <v-text-field
      :append-icon="hidePass ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="hidePass = !hidePass"
      :type="hidePass ? 'password' : 'text'"
      name="password"
      v-model.trim="formData.password"
      label="Senha"
      hint="Mínimo de 8 caracteres"
      min="8"
      :readonly="pending"
      :rules="passwordRules"
    />

    <v-layout justify-space-between>
      <v-btn
        elevation-1
        color="white green--text"
        small
        :disabled="pending"
        :to="'/cadastro'"
      >
        Voltar
      </v-btn>

      <v-btn
        color="success"
        small
        :disabled="!valid || entityFound === null"
        :loading="pending"
        @click="register"
      >
        Cadastrar
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
import validationRules from '@/mixins/validation-rules';
import personService from '@/services/person-service';
import authService from '@/services/auth-service';
import masks from '@/utils/masks';

export default {
  data() {
    return {
      entitySearch: '',

      entityFound: null,
      formData: {
        cpf: '',
      },
      pending: false,
      validationRules,
      masks,
      valid: true,
      hidePass: true,
      nameRules: [validationRules.required],
      emailRules: [validationRules.required, validationRules.email],
      passwordRules: [validationRules.required, validationRules.passwordMinLength],
      cpfRules: [validationRules.required, validationRules.cpf],
      dialogEntityNotRegistred: false,
    };
  },
  mixins: [validationRules],
  methods: {
    searchPerson() {
      personService
        .searchForLink({ q: this.entitySearch })
        .then(({ data }) => {
          if (data.length === 0) {
            this.dialogEntityNotRegistred = true;
            this.entityFound = null;
          } else {
            [this.entityFound] = data;
          }
        })
        .catch(() => {
          this.entityFound = null;
          this.dialogEntityNotRegistred = true;
        });
    },
    register() {
      this.pending = true;
      const payload = {
        ...this.formData,
        cpf_cnpj: this.entityFound.cpf_cnpj,
      };
      authService
        .linkedRegister(payload)
        .then(() => {
          this.$router.push({ name: 'registerSuccess' });
        })
        .catch(({ response }) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: response.data[0],
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.pending = false;
        });
    },
    getValidationRulesForCpfCnpj(cpfCnpj) {
      const rules = [];
      rules.push(cpfCnpj.length < 12 ? validationRules.cpf : validationRules.cnpj);
      return rules;
    },
    getMask(cpfCnpj) {
      return cpfCnpj.length < 12 ? '###.###.###-###' : '##.###.###/####-##';
    },
  },
};
</script>
<style>
  .v-input__append-outer {
    margin-top: 0 !important;
    margin-left: 0 !important;
  }
</style>

