var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "registerForm",
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.dialogEntityNotRegistred,
            callback: function ($$v) {
              _vm.dialogEntityNotRegistred = $$v
            },
            expression: "dialogEntityNotRegistred",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "text-xs-center", attrs: { xs12: "" } },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "red darken-1", size: "94" } },
                            [
                              _vm._v(
                                "\n              mdi-close-circle-outline\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("h3", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            "\n              Entidade não cadastrada\n            "
                          ),
                        ]),
                      ]),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("p", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            "\n              Caso você seja o responsável pela entidade, selecione\n              cadastrar para concluir o cadastro.\n            "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialogEntityNotRegistred = false
                        },
                      },
                    },
                    [_vm._v("\n          Cancelar\n        ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "green darken-1 white--text",
                        to: "/cadastro/entidade",
                      },
                    },
                    [_vm._v("\n          Cadastrar Entidade\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-text-field", {
        attrs: {
          mask: _vm.getMask(_vm.entitySearch),
          rules: _vm.getValidationRulesForCpfCnpj(_vm.entitySearch),
          required: "",
          small: "",
          box: "",
          label: "CPF ou CNPJ da Entidade",
        },
        scopedSlots: _vm._u([
          {
            key: "append-outer",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "green", fab: "", small: "" },
                    on: {
                      click: function ($event) {
                        return _vm.searchPerson()
                      },
                    },
                  },
                  [
                    _c("v-icon", { attrs: { color: "white" } }, [
                      _vm._v("\n          mdi-magnify\n        "),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.entitySearch,
          callback: function ($$v) {
            _vm.entitySearch = $$v
          },
          expression: "entitySearch",
        },
      }),
      _vm.entityFound
        ? _c("span", { staticClass: "mb-4" }, [
            _vm._v("\n    Entidade: " + _vm._s(_vm.entityFound.name) + "\n  "),
          ])
        : _vm._e(),
      _c("v-text-field", {
        attrs: {
          label: "CPF",
          rules: _vm.cpfRules,
          required: "",
          mask: "###.###.###-##",
          readonly: _vm.pending,
        },
        model: {
          value: _vm.formData.cpf,
          callback: function ($$v) {
            _vm.$set(
              _vm.formData,
              "cpf",
              typeof $$v === "string" ? $$v.trim() : $$v
            )
          },
          expression: "formData.cpf",
        },
      }),
      _c("v-text-field", {
        attrs: {
          label: "Nome",
          rules: _vm.nameRules,
          required: "",
          readonly: _vm.pending,
        },
        model: {
          value: _vm.formData.name,
          callback: function ($$v) {
            _vm.$set(
              _vm.formData,
              "name",
              typeof $$v === "string" ? $$v.trim() : $$v
            )
          },
          expression: "formData.name",
        },
      }),
      _c("v-text-field", {
        attrs: {
          label: "E-mail",
          type: "email",
          rules: _vm.emailRules,
          readonly: _vm.pending,
          required: "",
        },
        model: {
          value: _vm.formData.email,
          callback: function ($$v) {
            _vm.$set(
              _vm.formData,
              "email",
              typeof $$v === "string" ? $$v.trim() : $$v
            )
          },
          expression: "formData.email",
        },
      }),
      _c("v-text-field", {
        attrs: {
          "append-icon": _vm.hidePass ? "mdi-eye" : "mdi-eye-off",
          type: _vm.hidePass ? "password" : "text",
          name: "password",
          label: "Senha",
          hint: "Mínimo de 8 caracteres",
          min: "8",
          readonly: _vm.pending,
          rules: _vm.passwordRules,
        },
        on: {
          "click:append": function ($event) {
            _vm.hidePass = !_vm.hidePass
          },
        },
        model: {
          value: _vm.formData.password,
          callback: function ($$v) {
            _vm.$set(
              _vm.formData,
              "password",
              typeof $$v === "string" ? $$v.trim() : $$v
            )
          },
          expression: "formData.password",
        },
      }),
      _c(
        "v-layout",
        { attrs: { "justify-space-between": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                "elevation-1": "",
                color: "white green--text",
                small: "",
                disabled: _vm.pending,
                to: "/cadastro",
              },
            },
            [_vm._v("\n      Voltar\n    ")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                color: "success",
                small: "",
                disabled: !_vm.valid || _vm.entityFound === null,
                loading: _vm.pending,
              },
              on: { click: _vm.register },
            },
            [_vm._v("\n      Cadastrar\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }